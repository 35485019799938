<template>
  <PageContentLayoutOnly>
    <div class="title pl-2">{{ $t('mailDelivery.title') }}</div>
    <div class="tile is-parent is-12 p-2">
      <article class="tile is-child notification is-12">
        <div class="container" v-if="columns && columns != undefined" style="display: flex;">
          <div class="col" v-for="(column, index) in columns" :key="index">
            <div class="item-container" v-for="(item, index) in Object.keys(column)" :key="index">
              <div class="card p-2" v-if="column[item] && column[item] != undefined">
                <span>
                  <div class="container item-container card-content card p-2">
                    <div class="media">
                      <div class="media-content" style="overflow: hidden !important;">
                        <p class="title is-4">
                          <span>{{ `${column[item].mailingType.name || ''}` }}</span
                          ><span class="float-right">
                            <b-button
                              class="is-small font-small is-link is-light"
                              @click.prevent="openEditModal(column[item])"
                              ><b-icon icon="pencil" class="mdi-18px float-right"
                            /></b-button>
                          </span>
                        </p>
                        <p
                          v-if="
                            column[item].computedEmailAddresses &&
                              column[item].computedEmailAddresses !== undefined
                          "
                          class="subtitle is-6"
                        >
                          <span
                            ><label class="label">{{
                              `Email To: ${column[item].computedEmailAddresses || ''}`
                            }}</label></span
                          >
                        </p>
                        <p
                          v-else-if="
                            column[item].computedAddress &&
                              column[item].computedAddress !== undefined
                          "
                          class="subtitle is-6"
                        >
                          <span
                            ><label class="label">{{
                              `Mail To: ${parsedAddress(column[item].computedAddress) || ''}`
                            }}</label></span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
    <Modal :toggle.sync="toggle">
      <MailDeliveryModal :toggle="toggle" :ownerID="currentOwnerId" :mailDelivery="mailDelivery">
        <template v-slot:header>Edit Mail Delivery</template>
      </MailDeliveryModal>
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Modal from '@/components/Modal'
import MailDeliveryModal from './component/MailDeliveryModal'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { ownerMailDeliveryStore } from '@/services/OwnerMailingDeliveryMethod/store'
import parseAddress from '@/utilities/address/parse'

import moment from 'moment'

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'MailDeliveryTab',
  components: {
    PageContentLayoutOnly,
    Modal,
    MailDeliveryModal
  },

  data: function() {
    return {
      isDebug: true,
      loading: true,
      toggle: false,
      confirmModal: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      formatFn: function(value) {
        return value != null ? value : ''
      },
      formatNumberFn: function(value) {
        return value != null ? value : 0
      },
      parsedAddress: function(value) {
        return parseAddress({ address: value })
      },
      selectedRow: null,
      mailDeliveryList: [],
      mailDelivery: null,
      cols: 2
    }
  },

  created() {},

  computed: {
    ...mapState({
      breakpoint: state => state.win.breakpoint
    }),
    ...mapGetters('user', ['authUser', 'currentUnitId', 'currentHoaId', 'currentOwnerId']),

    columns: function() {
      let columns = []
      let count = 0

      console.debug('loading mail delivery columns....')

      Array.from(this.mailDeliveryList).forEach(element => {
        if (element && element != undefined) {
          if (element && element != undefined && element.mailingType.name) {
            count += element.mailingType.name.length
          }
        }
      })

      let objectKeys = Object.keys(this.mailDeliveryList)
      if (objectKeys && objectKeys != undefined) {
        let totalLength = objectKeys.length + count

        const multiplier = this.breakpoint !== 'desktop' ? 1 : 0.05

        totalLength = totalLength * multiplier

        let mid = Math.ceil(totalLength / this.cols)

        for (let col = 0; col < this.cols; col++) {
          columns.push(
            Object.entries(this.mailDeliveryList)
              .slice(col * mid, col * mid + mid)
              .map(entry => entry[1])
          )
        }
      }

      return columns
    }
  },

  mounted() {
    this.initializeMailDelivery()
    this.getMailDeliveries()
    if (this.isDebug == true) console.debug('mounted currentOwnerId=' + this.currentOwnerId)
  },

  watch: {
    currentHoaId() {
      this.getMailDeliveries()
    },
    currentOwnerId() {
      this.getMailDeliveries()
    },
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  methods: {
    reload() {
      this.getMailDeliveries()
    },
    async initializeMailDelivery() {
      console.log('initializing mail delivery ownerId=' + this.currentOwnerId)
      this.loading = true
      if (this.currentOwnerId && this.currentOwnerId != undefined) {
        await ownerMailDeliveryStore.dispatch('initializeMailDelivery', {
          ownerID: this.currentOwnerId,
          done: () => {
            this.loading = false
          }
        })
      }
      this.loading = false
    },
    openEditModal(editMailDelivery) {
      this.toggle = true
      this.mailDelivery = editMailDelivery
    },
    async getMailDeliveries() {
      this.loading = true

      if (this.currentOwnerId && this.currentOwnerId != undefined) {
        await ownerMailDeliveryStore
          .dispatch('getOwnerMailDeliveryList', {
            ownerID: this.currentOwnerId
          })
          .then(({ list }) => {
            if (list) {
              this.mailDeliveryList = list

              if (this.isDebug == true)
                console.debug('mailDeliveryList=' + JSON.stringify(this.mailDeliveryList))
            }
          })
      }
      this.loading = false
    }
  },

  i18n: {
    messages: {
      en: { mailDelivery: { title: 'Mail Delivery' } }
    }
  }
}
</script>

<style lang="scss">
#mail-delivery.my-grid-class {
  height: 400px;
}

#mail-delivery.tbody {
  min-height: 100px !important;
  overflow: auto !important;
}

#mail-delivery.my-grid-class {
  height: 400px;
}

#mail-delivery.tbody {
  min-height: 100px !important;
  overflow: auto !important;
}

#mail-delivery.col {
  margin: 2px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 50% !important;
}

#mail-delivery.item-container {
  font-weight: bold;
  padding: 2px;
  margin: 2px;
}
</style>
